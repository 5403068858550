// React components

export default function data(d) {
  const re = (id) => {
    return (
      <div onClick={() => (window.location.href = `/vehicle-details/${id}`)}>View Details</div>
    );
  };

  return {
    columns: [
      { Header: "UID", accessor: "vehicle_id", align: "center" },
      { Header: "Manufactured", accessor: "manufacturer_name", width: "45%", align: "center" },
      { Header: "Name", accessor: "vehicle_name", align: "left" },
      { Header: "Year", accessor: "vehicle_type", align: "center" },
      { Headers: "action", accessor: "navigate", align: "center" },
    ],

    rows: d.map((vehicle) => ({
      ...vehicle,
      navigate: re(vehicle.vehicle_id),
    })),
  };
}
