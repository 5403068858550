// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, FormGroup, FormControlLabel } from "@mui/material";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent, Checkbox } from "@mui/material";
import requestService from "service";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/manufacturer/data/authorsTableData";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";

function Manufactures() {
  const [data, setData] = useState([]);
  const { columns, rows } = authorsTableData(data);

  const [model, setModel] = useState(false);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const openModal = () => {
    setModel(true);
  };
  const getManufacturers = async () => {
    requestService.get("manufacturers").then((response) => {
      setData(response.data);
    });
  };
  useEffect(() => {
    requestService.get("vehicle-types").then((response) => {
      setVehicleTypes(response);
    });
    getManufacturers();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Manufactures{" "}
                  <MDButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => openModal()}
                  >
                    Add manufacturer
                  </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={model} onClose={() => setModel(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add Manufacturer</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ manufacturerName: "", manufacturerDetails: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.manufacturerName) {
                errors.manufacturerName = "Manufacturer Name is required";
              }
              if (!values.manufacturerDetails) {
                errors.manufacturerDetails = "Manufacturer Details are required";
              }
              if (!values.image_path) {
                errors.image_path = "image_path Details are required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Handle form submission logic here
              requestService
                .post("manufacturers", {
                  name: values.manufacturerName,
                  country: values.manufacturerDetails,
                  established_year: values.established_year,
                  vehicle_types: selectedVehicleTypes,
                  image_path: values.image_path,
                  man_desc: values.man_desc,
                })
                .then((response) => {
                  alert("Manufacturer added successfully");
                  getManufacturers();
                  setSubmitting(false);
                  setModel(false); // Close the modal after submission
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer Name</MDTypography>
                  <MDInput
                    type="text"
                    fullWidth
                    required
                    name="manufacturerName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.manufacturerName}
                    error={touched.manufacturerName && Boolean(errors.manufacturerName)}
                    helperText={touched.manufacturerName && errors.manufacturerName}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer Image Path</MDTypography>
                  <MDInput
                    type="text"
                    fullWidth
                    required
                    name="image_path"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.image_path}
                    error={touched.image_path && Boolean(errors.image_path)}
                    helperText={touched.image_path && errors.image_path}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer Country</MDTypography>
                  <MDInput
                    name="manufacturerDetails"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.manufacturerDetails}
                    fullWidth
                  />
                  {touched.manufacturerDetails && errors.manufacturerDetails && (
                    <div style={{ color: "red" }}>{errors.manufacturerDetails}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer Description</MDTypography>
                  <MDInput
                    name="man_desc"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.man_desc}
                    fullWidth
                  />
                  {touched.manufacturerDetails && errors.manufacturerDetails && (
                    <div style={{ color: "red" }}>{errors.manufacturerDetails}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer established year</MDTypography>
                  <MDInput
                    name="established_year"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.established_year}
                    fullWidth
                  />
                  {touched.established_year && errors.established_year && (
                    <div style={{ color: "red" }}>{errors.established_year}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDTypography variant="body2">Select Vehicle Types</MDTypography>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {vehicleTypes.map((vehicleType) => (
                          <FormControlLabel
                            key={vehicleType.vehicle_type_id}
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(selectedVehicleTypes) &&
                                  selectedVehicleTypes.includes(vehicleType.vehicle_type_id)
                                }
                                onChange={(event) => {
                                  const { checked } = event.target;
                                  const currentSelectedVehicleTypes = Array.isArray(
                                    selectedVehicleTypes
                                  )
                                    ? selectedVehicleTypes
                                    : [];
                                  if (checked) {
                                    setSelectedVehicleTypes([
                                      ...selectedVehicleTypes,
                                      vehicleType.vehicle_type_id,
                                    ]);
                                  } else {
                                    setSelectedVehicleTypes(
                                      selectedVehicleTypes.filter(
                                        (id) => id !== vehicleType.vehicle_type_id
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            label={vehicleType.type_name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>
                <MDButton type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Submit
                </MDButton>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Manufactures;
