import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Formik } from "formik";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import requestService from "service";

const VehicleDetails = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [variants, setVariants] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [images, setImages] = useState([]);
  const [features, setFeatures] = useState([]);
  const [vehicleId, setVehicleId] = useState(null);
  const [actionVal, setActionVal] = useState("");

  const handleOpen = async () => {
    setOpen(true);
    await getVehicleFeatures();
  };
  const handleClose = () => {
    setOpen(false);
    setActionVal("");
    setOpenImage(false);
  };
  const getVehicleDetails = async () => {
    try {
      const response = await requestService.get(`vehicles/${id}`);
      setVehicle(response.data[0]);
      setVehicleId(response.data[0].vehicle_id);
      setImages(response?.images?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getVehicleVariants = async () => {
    requestService.get(`vehicles/variants/${id}`).then((response) => {
      setVariants(response.data);
    });
  };
  const getVehicleFeatures = async () => {
    const response = await requestService
      .get(`features/vehicle-type/${vehicle?.vehicle_type_id}`)
      .then((response) => {
        setFeatures(response.data);
      });
  };
  const doAction = (val) => {
    setActionVal(val);
    switch (val) {
      case "addImages":
        setOpenImage(true);
        break;
      case "addVariant":
        handleOpen();
        break;
      case "homeBestSelling":
        setBestSellingHome(true);
        break;
    }
  };
  useEffect(() => {
    getVehicleDetails().then((response) => {
      getVehicleVariants();
    });
  }, [id, vehicleId]);

  const StandardImageList = ({ itemData }) => {
    StandardImageList.propTypes = {
      itemData: PropTypes.arrayOf(
        PropTypes.shape({
          image_path: PropTypes.string.isRequired,
        })
      ).isRequired,
    };
    return (
      <ImageList sx={{ width: "100%", height: 500 }} cols={3} rowHeight={400}>
        {itemData &&
          itemData?.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.image_path}?w=164&h=164&fit=crop&auto=format`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
      </ImageList>
    );
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Section 1: Display Images with Scroll and Title */}
              <Typography variant="h4" gutterBottom sx={{ mt: 4, ml: 5 }}>
                {vehicle?.manufacturer_name} - {vehicle?.vehicle_name} - {vehicle?.vehicle_type}{" "}
                Images
              </Typography>

              <Box sx={{ flexDirection: "row-reverse", mt: "5" }}>
                Select Action
                <Select
                  variant="outlined"
                  style={{ maxWidth: "150px", minWidth: "150px" }}
                  onChange={(e) => doAction(e.target.value)}
                  value={actionVal}
                  defaultValue={actionVal}
                >
                  <MenuItem value="">Select Action</MenuItem>
                  <MenuItem value="addVariant">Add variant</MenuItem>
                  <MenuItem value="addImages">Add Images</MenuItem>
                  <MenuItem value="homeBestSelling">Add to Best Selling</MenuItem>
                </Select>
              </Box>

              <Box sx={{ display: "flex", overflowX: "scroll", mb: 2 }}>
                {/* Replace with actual image URLs */}
                <StandardImageList itemData={images} />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {/* Section 3: Display All Variants of Vehicle in Table */}
            <Card>
              <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 5 }}>
                {vehicle?.manufacturer_name} - {vehicle?.vehicle_name} - {vehicle?.vehicle_type}{" "}
                Vehicle Variants
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Variant Name</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {variants.map((variant, index) => (
                      <TableRow key={`Variant_${index}`}>
                        <TableCell>{variant.variant_name}</TableCell>
                        <TableCell>{variant.base_price}</TableCell>
                        <TableCell>{variant.status == 1 ? "Active" : "Inactive"}</TableCell>
                        <TableCell>
                          <a href={`/vehicle-details/${id}/variant/${variant.variant_id}`}>
                            View Details
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openImage} onClose={handleClose}>
        <DialogTitle>Add Images</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ imagePaths: [""] }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await requestService.post(`vehicles/${id}/images`, {
                images: values.imagePaths,
                type: vehicle?.vehicle_type,
              });
              handleClose();
              await getVehicleVariants();
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                {values.imagePaths.map((path, index) => (
                  <TextField
                    key={index}
                    name={`imagePaths[${index}]`}
                    label={`Image Path ${index + 1}`}
                    value={path}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                ))}
                <Button
                  type="button"
                  onClick={() => {
                    values.imagePaths.push("");
                  }}
                  variant="outlined"
                >
                  Add Another Image Path
                </Button>
                <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={isSubmitting}>
                  Save
                </Button>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {/* Modal for Adding Vehicle Variant */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Add Variant</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: 4,
              backgroundColor: "white",
              borderRadius: 2,
              margin: "auto",
              marginTop: "100px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Add Vehicle Variant
            </Typography>
            <Formik
              initialValues={{ variantName: "", price: "", status: "", features: [""] }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const variantData = {
                  variantName: values.variantName,
                  price: values.price,
                  status: values.status,
                  vehicle_id: vehicleId,
                  features: features.map((item, index) => ({
                    feature_name: item.feature_name,
                    feature_id: item.feature_id,
                    value: values.features[index] || "",
                    default_value: item.default_value,
                  })),
                };
                requestService
                  .post("vehicles/addVariant", variantData)
                  .then((response) => {
                    alert(response?.message);
                    handleClose();
                    getVehicleDetails();
                  })
                  .error((error) => {
                    alert(error?.message);
                    console.log(error);
                  });
              }}
            >
              {({ handleChange, handleSubmit, values, isSubmitting }) => (
                <MDBox component="form" onSubmit={handleSubmit}>
                  <MDInput
                    name="variantName"
                    label="Variant Name"
                    onChange={handleChange}
                    fullWidth
                  />
                  <Box sx={{ mb: 2 }}></Box>
                  <MDInput
                    name="price"
                    label="Price"
                    type="number"
                    onChange={handleChange}
                    fullWidth
                  />
                  <Box sx={{ mb: 2 }}></Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 400 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Feature Name</TableCell>
                          <TableCell>Value</TableCell>
                          <TableCell>Default value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {features.map((item, index) => (
                          <TableRow key={`feature_${index}`}>
                            <TableCell>{item.feature_name}</TableCell>
                            <TableCell>
                              {item.feature_type === "text" || item.feature_type === "number" ? (
                                <TextField
                                  type={item.feature_type}
                                  label="Value"
                                  variant="outlined"
                                  name={`features[${index}]`}
                                  onChange={handleChange}
                                  placeholder={item.description}
                                  value={values.features[index] || ""}
                                />
                              ) : item.feature_type === "select" ? (
                                <Select
                                  label="Value"
                                  variant="outlined"
                                  name={`features[${index}]`}
                                  onChange={handleChange}
                                  value={values.features[index] || ""}
                                  placeholder={item.description}
                                  style={{ minWidth: "200px" }}
                                >
                                  {item.description.split(",").map((desc, descIndex) => (
                                    <MenuItem key={`desc_${descIndex}`} value={desc.trim()}>
                                      {desc.trim()}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : null}
                              {/* <TextField
                                type={item.feature_type}
                                label="Value"
                                variant="outlined"
                                name={`features[${index}]`}
                                onChange={handleChange}
                                value={values.features[index] || ""}
                              /> */}
                            </TableCell>
                            <TableCell>
                              {item.default_value} {item?.feature_unit}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ mb: 2 }}></Box>
                  <MDInput name="status" label="Status" onChange={handleChange} fullWidth />
                  <Box sx={{ mb: 2 }}></Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    disabled={isSubmitting}
                  >
                    Add Variant
                  </Button>
                </MDBox>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default VehicleDetails;
