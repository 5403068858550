import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import RenderSuccessSB from "layouts/notifications/RenderSuccessSB";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  ImageListItemBar,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Formik } from "formik";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import requestService from "service";

const VehicleVariant = () => {
  const { id, variantId } = useParams();
  const [vehicle, setVehicle] = useState({});
  const [vehicleId, setVehicleId] = useState(null);
  const [features, setFeatures] = useState([]);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [bestSellingHome, setBestSellingHome] = useState(false);
  const [featureCategories, setFeatureCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState({});
  const [edit, setEdit] = useState(false);
  const [vehicleTypeId, setVehicleTypeId] = useState(null);
  const [vFeatures, setVFeatures] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const fetchFeatureCategories = async () => {
    try {
      const response = await requestService.get("categories/feature-categories");
      setFeatureCategories(response.data);
    } catch (error) {
      console.error("Error fetching feature categories:", error);
    }
  };
  const getVehicleFeatures = async () => {
    await requestService.get(`features/vehicle-type/${vehicleTypeId}`).then((response) => {
      setVFeatures(response.data);
    });
  };

  const getVehicleDetails = async () => {
    try {
      const response = await requestService.get(`vehicles/${id}`);
      setVehicle(response.data[0]);
      setVehicleId(response.data[0].vehicle_id);
      setVehicleTypeId(response.data[0].vehicle_type_id);
      const data = await requestService.get(`vehicles/${id}/features/${variantId}`);
      setFeatures(data?.data);
      setImages(data?.images?.data);
      //   setImages(response?.images?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = (item) => {
    const conf = confirm("Are you to delete Image ?");
    if (conf) {
      try {
        requestService.post(`vehicles/delete-image/${item.id}`).then(async (response) => {
          setShowSuccess(true);
          await getVehicleDetails();
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const StandardImageList = ({ itemData }) => {
    StandardImageList.propTypes = {
      itemData: PropTypes.arrayOf(
        PropTypes.shape({
          image_path: PropTypes.string.isRequired,
        })
      ).isRequired,
    };
    return (
      <ImageList sx={{ width: "100%", height: 500 }} cols={3} rowHeight={400}>
        {itemData &&
          itemData?.map((item) => (
            <ImageListItem key={item.img}>
              <ImageListItemBar
                title={item.title}
                actionIcon={
                  <IconButton
                    onClick={() => handleDelete(item)}
                    aria-label={`delete ${item.title}`}
                    sx={{ color: "white" }}
                    onMouseOver={(e) => (e.currentTarget.style.color = "red")}
                    onMouseOut={(e) => (e.currentTarget.style.color = "white")}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              />
              <img
                srcSet={`${item.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.image_path}?w=164&h=164&fit=crop&auto=format`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
      </ImageList>
    );
  };

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setBestSellingHome(false);
    setShowSuccess(false);
  };
  const editFet = async () => {
    setEdit(true);
    await getVehicleFeatures();
  };
  useEffect(() => {
    getVehicleDetails();
    fetchFeatureCategories();
  }, [id, vehicleId]);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* {showSuccess && ( */}
      <RenderSuccessSB
        title="Image Deleted "
        content="Image Deleted Successfully"
        open={showSuccess}
        onClose={showSuccess}
      />
      {/* )} */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Section 1: Display Images with Scroll and Title */}
              <Typography variant="h4" gutterBottom sx={{ mt: 4, ml: 5 }}>
                {vehicle?.manufacturer_name} - {vehicle?.vehicle_name} - {vehicle?.vehicle_type}{" "}
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="feature-select-label">Select Feature</InputLabel>
                <Select
                  labelId="feature-select-label"
                  onChange={(e) => {
                    setSelectedFeature(e.target.value);
                    setOpenDialog(true);
                  }}
                >
                  {featureCategories.map((feature) => (
                    <MenuItem key={feature} value={feature}>
                      {feature.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Add to Category: {selectedFeature.name}</DialogTitle>
                <DialogContent>
                  <Formik
                    initialValues={{ startDate: "", endDate: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.startDate) {
                        errors.startDate = "Start date is required";
                      }
                      if (!values.endDate) {
                        errors.endDate = "End date is required";
                      }
                      return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);
                      await requestService.post(`feature/feature-category-variant-vehicles`, {
                        start_date: values.startDate,
                        end_date: values.endDate,
                        category_id: selectedFeature.id,
                        vehicle_id: vehicleId,
                        variant_id: variantId,
                      });
                      setOpenDialog(false);
                      setSubmitting(false);
                    }}
                  >
                    {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
                      <MDBox component="form" onSubmit={handleSubmit}>
                        <TextField
                          name="startDate"
                          label="Start Date"
                          type="date"
                          value={values.startDate}
                          onChange={handleChange}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(errors.startDate)}
                          helperText={errors.startDate}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          name="endDate"
                          label="End Date"
                          type="date"
                          value={values.endDate}
                          onChange={handleChange}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(errors.endDate)}
                          helperText={errors.endDate}
                          sx={{ mb: 2 }}
                        />
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                          Add Feature
                        </Button>
                      </MDBox>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Typography variant="h4" gutterBottom sx={{ mt: 4, ml: 5 }}>
                {" "}
                Images{" "}
                <Button sx={{ ml: 50 }} variant="contained" onClick={handleOpen}>
                  Add Images
                </Button>
                {/* <Button
                  sx={{ ml: 50 }}
                  variant="contained"
                  onClick={() => setBestSellingHome(true)}
                >
                  Add Best Selling
                </Button> */}
                <StandardImageList itemData={images} />
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Add Images</DialogTitle>
                  <DialogContent>
                    <Formik
                      initialValues={{ imagePaths: [""] }}
                      onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        await requestService.post(`vehicles/${id}/images/${variantId}`, {
                          images: values.imagePaths,
                        });
                        handleClose();
                        getVehicleDetails();
                      }}
                    >
                      {({ values, handleChange, handleSubmit, isSubmitting }) => (
                        <MDBox component="form" onSubmit={handleSubmit}>
                          {values.imagePaths.map((path, index) => (
                            <TextField
                              key={index}
                              name={`imagePaths[${index}]`}
                              label={`Image Path ${index + 1}`}
                              value={path}
                              onChange={handleChange}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          ))}
                          <Button
                            type="button"
                            onClick={() => {
                              values.imagePaths.push("");
                            }}
                            variant="outlined"
                          >
                            Add Another Image Path
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 2 }}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </MDBox>
                      )}
                    </Formik>
                  </DialogContent>
                </Dialog>
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Typography variant="h4" gutterBottom sx={{ mt: 4, ml: 5 }}>
                Features{" "}
                <Button variant="contained" onClick={() => editFet()}>
                  Edit
                </Button>
                {features.map((feature, findex) => {
                  return (
                    <Accordion key={`feature_${findex}`}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {feature.group_name}
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table>
                            <TableRow>
                              <TableCell style={{ width: "10%" }}>SNO</TableCell>
                              <TableCell style={{ width: "20%" }}>Feature Name</TableCell>
                              <TableCell>Value</TableCell>
                            </TableRow>

                            {feature?.items?.map((item, index) => {
                              return (
                                <TableRow key={`fet_${index}`}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{item.feature_name}</TableCell>
                                  <TableCell>
                                    {item.value} {item?.unit && item.unit != "null" && item.unit}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={bestSellingHome} onClose={handleClose}>
        <DialogTitle>Add Best Selling Dates</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ startDate: "", endDate: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.startDate) {
                errors.startDate = "Required";
              }
              if (!values.endDate) {
                errors.endDate = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                await requestService.post(`vehicles/${vehicleId}/best-selling`, {
                  start_date: values.startDate,
                  end_date: values.endDate,
                  variant_id: variantId,
                });
                handleClose();
                // Optionally, refresh vehicle details or show a success message
              } catch (error) {
                console.error("Error adding best selling dates:", error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                <MDBox sx={{ mb: 5 }} />
                <TextField
                  label="Start Date"
                  type="date"
                  name="startDate"
                  onChange={handleChange}
                  value={values.startDate}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors.startDate)}
                  helperText={errors.startDate}
                />
                <MDBox sx={{ mb: 5 }} />
                <TextField
                  label="End Date"
                  type="date"
                  name="endDate"
                  onChange={handleChange}
                  value={values.endDate}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors.endDate)}
                  helperText={errors.endDate}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                >
                  Add Best Selling Dates
                </Button>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};
export default VehicleVariant;
