import axios from "axios";

// Set default base URL for Axios (Optional)
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || "https://api.ustech.in/api/";//"http://localhost:5000/api/";

// Common service for GET, POST, PUT, DELETE requests
const requestService = {
  get: async (url, params = {}, headers = {}) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await axios.get(url, { params, headers });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  post: async (url, data = {}, headers = {}) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await axios.post(url, data, { headers });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  put: async (url, data = {}, headers = {}) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await axios.put(url, data, { headers });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  delete: async (url, headers = {}) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await axios.delete(url, { headers });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

// Error handling function
const handleError = (error) => {
  if (error.response && error.response.status === 401) {
    window.location.href = "/authentication/sign-in";
  }
  if (error.response) {
    // Request made and server responded with a status outside 2xx
    console.error("Response Error:", error.response.data);
    console.error("Response Status:", error.response.status);
    console.error("Response Headers:", error.response.headers);
  } else if (error.request) {
    // Request made but no response received
    console.error("No Response:", error.request);
  } else {
    // Something else happened while setting up the request
    console.error("Error:", error.message);
  }
  throw error; // Re-throw error to handle it elsewhere if needed
};

export default requestService;
