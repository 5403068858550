/** 
  All of the routes for the React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Manufactures from "layouts/manufacturer";
import VehicleTypes from "layouts/vehicletypes";
import Vehicles from "layouts/vehicles";
import AddVehicle from "layouts/addvehicle";
import VehicleFeatures from "layouts/features";
import VehicleDetails from "layouts/vehicleDetails";
import VehicleVariant from "layouts/vehiclevariant";
import FeatureCategories from "layouts/featurecategories";
import VehicleFeaturesGroups from "layouts/featureGroups";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import VehicleVariants from "layouts/vehiclevariants";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Feature Categories",
    key: "Categories",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/app-feature-cat",
    component: <FeatureCategories />,
  },
  {
    type: "collapse",
    name: "Vehicles",
    key: "Vehicles",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/vehicles",
    component: <Vehicles />,
  },
  {
    name: "Vehicles Details",
    key: "vehicle-details",
    route: "/vehicle-details/:id", // Added URL with id
    component: <VehicleDetails />,
  },
  {
    name: "Vehicles variant Details",
    key: "vehicle-variant-details",
    route: "/vehicle-details/:id/variant/:variantId", // Added URL with id
    component: <VehicleVariant />,
  },
  {
    type: "collapse",
    name: "Add Vehicle",
    key: "addvehicle",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/addvehicle",
    component: <AddVehicle />,
  },
  {
    type: "collapse",
    name: "Vehicle Variants",
    key: "vehicle_variants",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/vehicle-variants",
    component: <VehicleVariants />,
  },
  {
    type: "collapse",
    name: "Vehicle Feature Groups",
    key: "feature-groups",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/vehicle-feature-groups",
    component: <VehicleFeaturesGroups />,
  },
  {
    type: "collapse",
    name: "Vehicle Features",
    key: "features",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/vehicle-features",
    component: <VehicleFeatures />,
  },
  {
    type: "collapse",
    name: "Manufactures",
    key: "manufactures",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/manufactures",
    component: <Manufactures />,
  },
  {
    type: "collapse",
    name: "Vehicle Types",
    key: "vehicletypes",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/vehicletypes",
    component: <VehicleTypes />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
