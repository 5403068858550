import MDSnackbar from "components/MDSnackbar";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
const RenderSuccessSB = ({ open, onClose, title, content }) => {
  RenderSuccessSB.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  return (
    <MDSnackbar
      color="success"
      icon="check"
      title={title}
      content={content}
      dateTime="Just now"
      open={open}
      //  onClose={closeSuccessSB} // Use the passed onClose prop for closing
      //  close={closeSuccessSB} // Use the passed onClose prop for closing
      autoHideDuration={300} // Auto close after 3 seconds
      bgWhite
    />
  );
};

export default RenderSuccessSB;
