// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import requestService from "service";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/vehicletypes/data/authorsTableData";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";

function VehicleTypes() {
  const [data, setData] = useState([]);
  const { columns, rows } = authorsTableData(data);
  const [model, setModel] = useState(false);

  const openModal = () => {
    setModel(true);
  };

  const getVehicleTypes = async () => {
    requestService.get("vehicle-types").then((response) => {
      setData(response);
    });
  };

  useEffect(() => {
    getVehicleTypes();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Vehicle Types{" "}
                  <MDButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => openModal()}
                  >
                    Add Vehicle Type
                  </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={model} onClose={() => setModel(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add Vehicle Types</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ name: "" }}
            validate={(values) => {
              const errors = {};
              if (!/^[a-zA-Z0-9]*$/.test(values.name)) {
                errors.name = "Name can only contain alphabets and numbers";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Handle form submission logic here
              setSubmitting(true);
              setModel(false);
              requestService.post("vehicle-types", { type_name: values.name }).then((response) => {
                alert("Added Successfully");
                setSubmitting(false);
              });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Name</MDTypography>
                  <MDInput
                    type="text"
                    name="name"
                    fullWidth
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </MDBox>
                <MDButton type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Submit
                </MDButton>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default VehicleTypes;
