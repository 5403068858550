import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import requestService from "service";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const FeatureCategories = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await requestService.get("categories/feature-categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleOpen = (category = null) => {
    setCurrentCategory(category);
    setEditMode(!!category);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentCategory(null);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (editMode) {
        await requestService.put(`categories/feature-categories/${currentCategory.id}`, values);
      } else {
        await requestService.post("categories/feature-categories", values);
      }
      fetchCategories();
      handleClose();
    } catch (error) {
      console.error("Error saving category:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await requestService.delete(`categories/feature-categories/${id}`);
        fetchCategories();
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={() => handleOpen()}>
            Add Feature Category
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
            <TableBody>
              {categories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleOpen(category)}>Edit</Button>
                    <Button onClick={() => handleDelete(category.id)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{editMode ? "Edit Feature Category" : "Add Feature Category"}</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: currentCategory ? currentCategory.name : "",
                description: currentCategory ? currentCategory.description : "",
                slug: currentCategory ? currentCategory.slug : "",
                keywords: currentCategory ? currentCategory.keywords : "",
                icon_image: currentCategory ? currentCategory.icon_image : "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Required";
                }

                return errors;
              }}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Category Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    sx={{ mb: 2 }} // Add spacing
                  />
                  <TextField
                    label="Description"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 2 }} // Add spacing
                  />
                  <TextField
                    label="Slug"
                    name="slug"
                    value={values.slug || ""}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 2 }} // Add spacing
                  />
                  <TextField
                    label="Keywords"
                    name="keywords"
                    value={values.keywords || ""}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 2 }} // Add spacing
                  />
                  <TextField
                    label="Icon Image URL"
                    name="icon_image"
                    value={values.icon_image || ""}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 2 }} // Add spacing
                  />
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      {editMode ? "Update" : "Add"}
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Grid>
    </DashboardLayout>
  );
};

export default FeatureCategories;
