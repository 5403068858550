// React components

export default function data(d) {
  return {
    columns: [
      { Header: "Id", accessor: "vehicle_type_id", align: "center" },
      { Header: "Name", accessor: "type_name", width: "45%", align: "left" },
      { Header: "Country", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
    ],

    rows: d,
  };
}
