// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, FormGroup, FormControlLabel, Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent, Checkbox } from "@mui/material";
import requestService from "service";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";

function VehicleFeaturesGroups() {
  const cols = [
    { Header: "ID", accessor: "id", align: "center" },
    { Header: "Name", accessor: "name", align: "left" },
    // { Header: "Default value", accessor: "default_value", width: "45%", align: "left" },
    // { Header: "Description", accessor: "description", align: "left" },
    { Header: "Status", accessor: "status", align: "center" },
  ];
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(cols);

  const [rows, setRows] = useState([]);

  const [model, setModel] = useState(false);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const openModal = () => {
    setModel(true);
  };
  const getFeatures = (vehicle_type_id) => {
    requestService.get(`features/vehicle-type/${vehicle_type_id}`).then((response) => {
      setRows(response.data);
    });
  };
  useEffect(() => {
    requestService.get("features-groups").then((response) => {
      setRows(response.data);
    });
    // getManufacturers();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Features Group
                  <MDButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => openModal()}
                  >
                    Add Vehicle Feature Group
                  </MDButton>
                </MDTypography>
              </MDBox>
              {/* <MDBox pt={3}>
                Select vehicle
                <Select variant="outlined" onChange={(e) => getFeatures(e.target.value)}>
                  <MenuItem defaultValue={""}>Select</MenuItem>
                  {vehicleTypes.map((item, index) => {
                    return (
                      <MenuItem value={item.vehicle_type_id} key={`Veh_type_${index}`}>
                        {item.type_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </MDBox> */}
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={model} onClose={() => setModel(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ group_name: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.group_name) {
                errors.group_name = "Group Name is required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              // Handle form submission logic here
              let errorStatus = false;
              try {
                requestService
                  .post("features-groups", {
                    name: values.group_name,
                  })
                  .then((response) => {});
              } catch (error) {
                console.log(error);
                errorStatus = true;
                alert("Error While creating feature");
              } finally {
                if (!errorStatus) {
                  alert("group_name added successfully");
                  setSubmitting(false);
                  setModel(false); // Close the modal after submission
                }
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Group Name</MDTypography>
                  <MDInput
                    type="text"
                    fullWidth
                    required
                    name="group_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.group_name}
                    error={touched.group_name && Boolean(errors.group_name)}
                    helperText={touched.group_name && errors.group_name}
                  />
                </MDBox>
                {touched.group_name && errors.group_name && (
                  <div style={{ color: "red" }}>{errors.group_name}</div>
                )}
                <MDButton type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Submit
                </MDButton>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default VehicleFeaturesGroups;
