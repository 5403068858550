// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, FormGroup, FormControlLabel, Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent, Checkbox } from "@mui/material";
import requestService from "service";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import authorsTableData from "layouts/manufacturer/data/authorsTableData";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

function AddVehicle() {
  const [data, setData] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const navigate = useNavigate();
  const getManufacturers = async () => {
    requestService.get("manufacturers").then((response) => {
      //   setData(response.data);
      const groupedData = response.data.reduce((acc, item) => {
        const {
          manufacturer_id,
          manufacturer_name,
          country,
          established_year,
          vehicle_type_id,
          type_name,
        } = item;
        if (!acc[manufacturer_id]) {
          acc[manufacturer_id] = {
            manufacturer_id,
            manufacturer_name,
            country,
            established_year,
            vehicle_types: [],
          };
        }
        acc[manufacturer_id].vehicle_types.push({
          vehicle_type_id,
          type_name,
        });
        return acc;
      }, {});
      setData(Object.values(groupedData));
    });
  };
  useEffect(() => {
    requestService.get("vehicle-types").then((response) => {
      setVehicleTypes(response);
    });
    getManufacturers();
  }, []);
  const addImagePath = () => {
    setImagePaths([...imagePaths, ""]); // Add a new empty string to the imagePaths array
  };
  const handleImagePathChange = (e, index) => {
    const newImagePaths = [...imagePaths];
    newImagePaths[index] = e.target.value; // Update the specific image path based on the index
    setImagePaths(newImagePaths); // Set the updated image paths
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={5}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Add Vehicle
                </MDTypography>
              </MDBox>
              <MDBox pt={3} m={5}>
                <Formik
                  initialValues={{
                    model_name: "",
                    vehicleType: "",
                    vehicleName: "",
                    manufacturer_id: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.manufacturer_id) {
                      errors.manufacturer_id = "Required";
                    }
                    if (!values.model_name) {
                      errors.model_name = "Required";
                    }
                    // if (!values.vehicleType) {
                    //   errors.vehicleType = "Required";
                    // }
                    // if (!values.vehicleName) {
                    //   errors.vehicleName = "Required";
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // Handle form submission
                    console.log("Form data", values);
                    const data = {
                      manufacturer_id: values.manufacturer_id.manufacturer_id,
                      model_name: values.model_name,
                      price: values.price,
                      vehicle_type_id: values.vehicleType,
                      fuel_type: "electric",
                      images: imagePaths,
                    };
                    requestService
                      .post("vehicles/add", data)
                      .then((response) => {
                        alert(response.message);
                        navigate(`/vehicle-details/${response.data.vehicle_id}`);
                      })
                      .error((error) => {
                        setSubmitting(false);
                        alert(response.message);
                      });
                    //setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <MDBox mb={2}>
                        <MDTypography variant="body2">Select Manufacturer</MDTypography>
                        <Select
                          name="manufacturer_id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.manufacturer_id}
                          error={touched.manufacturer_id && Boolean(errors.manufacturer_id)}
                          fullWidth
                          variant="outlined"
                        >
                          {data.map((manufacturer) => (
                            <MenuItem key={manufacturer.manufacturer_id} value={manufacturer}>
                              {manufacturer.manufacturer_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.manufacturer_id && errors.manufacturer_id && (
                          <MDTypography variant="caption" color="error">
                            {errors.manufacturer_id}
                          </MDTypography>
                        )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="body2">Select Vehicle Type</MDTypography>
                        <Select
                          name="vehicleType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vehicleType}
                          error={touched.vehicleType && Boolean(errors.vehicleType)}
                          fullWidth
                        >
                          {values?.manufacturer_id?.vehicle_types?.map((type) => (
                            <MenuItem key={type.vehicle_type_id} value={type.vehicle_type_id}>
                              {type.type_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.vehicleType && errors.vehicleType && (
                          <MDTypography variant="caption" color="error">
                            {errors.vehicleType}
                          </MDTypography>
                        )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="body2">Vehicle Name</MDTypography>
                        <MDInput
                          type="text"
                          name="model_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.model_name}
                          error={touched.model_name && Boolean(errors.model_name)}
                          fullWidth
                        />
                        {touched.model_name && errors.model_name && (
                          <MDTypography variant="caption" color="error">
                            {errors.model_name}
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="body2">Vehicle Model</MDTypography>
                        <MDInput
                          type="text"
                          name="vehicleModel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vehicleModel}
                          error={touched.vehicleModel && Boolean(errors.vehicleModel)}
                          fullWidth
                        />
                        {touched.vehicleModel && errors.vehicleModel && (
                          <MDTypography variant="caption" color="error">
                            {errors.vehicleModel}
                          </MDTypography>
                        )}
                      </MDBox>

                      <MDBox mb={2}>
                        <MDTypography variant="body2">Engine Capacity</MDTypography>
                        <MDInput
                          type="text"
                          name="engine_capacity"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.engine_capacity}
                          error={touched.engine_capacity && Boolean(errors.engine_capacity)}
                          fullWidth
                        />
                        {touched.engine_capacity && errors.engine_capacity && (
                          <MDTypography variant="caption" color="error">
                            {errors.engine_capacity}
                          </MDTypography>
                        )}
                      </MDBox>

                      {/* <MDBox mb={2}>
                        <MDTypography variant="body2">Fuel Type</MDTypography>
                        <Select
                          name="fuel_type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fuel_type}
                          error={touched.fuel_type && Boolean(errors.fuel_type)}
                          fullWidth
                        >
                          <MenuItem value="Electric" key={"ELEctric"}>
                            Petrol
                          </MenuItem>
                        </Select>
                        {touched.fuel_type && errors.fuel_type && (
                          <MDTypography variant="caption" color="error">
                            {errors.fuel_type}
                          </MDTypography>
                        )}
                      </MDBox> */}

                      <MDBox mb={2}>
                        <MDTypography variant="body2">Basic Selling Price</MDTypography>
                        <MDInput
                          type="number"
                          name="price"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.price}
                          error={touched.price && Boolean(errors.price)}
                          fullWidth
                        />
                        {touched.price && errors.price && (
                          <MDTypography variant="caption" color="error">
                            {errors.price}
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="body2">Image Paths</MDTypography>
                        <FormGroup>
                          {imagePaths.map((path, index) => (
                            <MDInput
                              key={index}
                              name={`image_path_${index}`}
                              onChange={(e) => handleImagePathChange(e, index)}
                              value={path}
                              fullWidth
                              placeholder="Enter image path"
                            />
                          ))}
                          <MDButton variant="contained" onClick={addImagePath} sx={{ mt: 1 }}>
                            Add Image Path
                          </MDButton>
                        </FormGroup>
                      </MDBox>
                      <MDButton type="submit" disabled={isSubmitting} variant="contained">
                        Submit
                      </MDButton>
                    </form>
                  )}
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddVehicle;
