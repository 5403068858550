// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, FormGroup, FormControlLabel, Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent, Checkbox } from "@mui/material";
import requestService from "service";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";

function VehicleFeatures() {
  const cols = [
    { Header: "Name", accessor: "feature_name", align: "left" },
    { Header: "Group Name", accessor: "name", width: "10%", align: "left" },
    { Header: "Default value", accessor: "default_value", width: "10%", align: "left" },
    { Header: "Description", accessor: "description", align: "left" },
    { Header: "Status", accessor: "status", align: "center" },
  ];
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(cols);
  const [groups, setGroups] = useState([]);

  const [rows, setRows] = useState([]);

  const [model, setModel] = useState(false);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const openModal = () => {
    setModel(true);
    requestService.get("features-groups").then((response) => {
      setGroups(response.data);
    });
  };
  // const getManufacturers = async () => {
  //   requestService.get("manufacturers").then((response) => {
  //     setData(response.data);
  //   });
  // };
  const getFeatures = (vehicle_type_id) => {
    requestService.get(`features/vehicle-type/${vehicle_type_id}`).then((response) => {
      setRows(response.data);
    });
  };
  useEffect(() => {
    requestService.get("vehicle-types").then((response) => {
      setVehicleTypes(response);
    });
    // getManufacturers();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Features{" "}
                  <MDButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => openModal()}
                  >
                    Add Vehicle Feature
                  </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                Select vehicle
                <Select variant="outlined" onChange={(e) => getFeatures(e.target.value)}>
                  <MenuItem defaultValue={""}>Select</MenuItem>
                  {vehicleTypes.map((item, index) => {
                    return (
                      <MenuItem value={item.vehicle_type_id} key={`Veh_type_${index}`}>
                        {item.type_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={model} onClose={() => setModel(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add Feature</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ feature_name: "", default_value: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.feature_name) {
                errors.feature_name = "Feature Name is required";
              }
              if (!values.default_value) {
                errors.default_value = "Default value required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              // Handle form submission logic here
              let errorStatus = false;
              try {
                selectedVehicleTypes.map((item) => {
                  requestService
                    .post("features/add", {
                      feature_name: values.feature_name,
                      default_value: values.default_value,
                      description: values.description,
                      vehicle_type_id: item,
                      feature_unit: values.feature_unit,
                      feature_type: values.feature_type,
                      feature_group_id: values.feature_group_id,
                    })
                    .then((response) => {});
                });
              } catch (error) {
                console.log(error);
                errorStatus = true;
                alert("Error While creating feature");
              } finally {
                if (!errorStatus) {
                  alert("feature added successfully");
                  setSubmitting(false);
                  setModel(false); // Close the modal after submission
                }
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Select Feature Group </MDTypography>
                  <Select
                    name="feature_group_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    required
                    value={values.feature_group_id}
                  >
                    {groups.map((item, index) => {
                      return (
                        <MenuItem value={`${item.id}`} key={`group_${index}`}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Feature Name</MDTypography>
                  <MDInput
                    type="text"
                    fullWidth
                    required
                    name="feature_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.feature_name}
                    error={touched.feature_name && Boolean(errors.feature_name)}
                    helperText={touched.feature_name && errors.feature_name}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Default Value</MDTypography>
                  <MDInput
                    name="default_value"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.default_value}
                    fullWidth
                  />
                  {touched.default_value && errors.default_value && (
                    <div style={{ color: "red" }}>{errors.default_value}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">UNIT :</MDTypography>
                  <MDInput
                    name="feature_unit"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.feature_unit}
                    fullWidth
                  />
                  {touched.default_value && errors.default_value && (
                    <div style={{ color: "red" }}>{errors.default_value}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">
                    Value Enter Type(Text, number, select) in Case of select enter in , separated
                  </MDTypography>
                  <MDInput
                    name="feature_type"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.feature_type}
                    fullWidth
                  />
                  {touched.default_value && errors.default_value && (
                    <div style={{ color: "red" }}>{errors.default_value}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Description</MDTypography>
                  <MDInput
                    name="description"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    fullWidth
                  />
                  {touched.description && errors.description && (
                    <div style={{ color: "red" }}>{errors.description}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDTypography variant="body2">
                      Select Vehicle Type to apply Feature
                    </MDTypography>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {vehicleTypes.map((vehicleType) => (
                          <FormControlLabel
                            key={vehicleType.vehicle_type_id}
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(selectedVehicleTypes) &&
                                  selectedVehicleTypes.includes(vehicleType.vehicle_type_id)
                                }
                                onChange={(event) => {
                                  const { checked } = event.target;
                                  const currentSelectedVehicleTypes = Array.isArray(
                                    selectedVehicleTypes
                                  )
                                    ? selectedVehicleTypes
                                    : [];
                                  if (checked) {
                                    setSelectedVehicleTypes([
                                      ...selectedVehicleTypes,
                                      vehicleType.vehicle_type_id,
                                    ]);
                                  } else {
                                    setSelectedVehicleTypes(
                                      selectedVehicleTypes.filter(
                                        (id) => id !== vehicleType.vehicle_type_id
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            label={vehicleType.type_name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>
                <MDButton type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Submit
                </MDButton>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default VehicleFeatures;
