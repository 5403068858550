import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
export default function data(d) {
  const newData = [];

  const BrandImage = (logo_path, manufacturer_name) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={logo_path} name={manufacturer_name} size="lg" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {manufacturer_name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  newData.push(
    ...d.map((item) => ({
      brand_image: BrandImage(item.logo_path, item.manufacturer_name),
      manufacturer_name: item.manufacturer_name,
      type_name: item.type_name,
      country: item.country,
      established_year: item.established_year,
    }))
  );

  return {
    columns: [
      { Header: "Logo", accessor: "brand_image", align: "center" },
      { Header: "Manufactured", accessor: "type_name", width: "45%", align: "left" },
      { Header: "Country", accessor: "country", align: "left" },
      { Header: "Established Year", accessor: "established_year", align: "center" },
    ],

    rows: newData,
  };
}
