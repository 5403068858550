// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, FormGroup, FormControlLabel, MenuItem, Select } from "@mui/material";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent, Checkbox } from "@mui/material";
import requestService from "service";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/vehicles/data/authorsTableData";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";

function Vehicles() {
  const [data, setData] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const { columns, rows } = authorsTableData(vehicles);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");

  const [model, setModel] = useState(false);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(false);

  const openModal = () => {
    setModel(true);
  };
  const getAllVehicles = async () => {
    // setSelectedVehicle(val);
    // let query = `?manufacturer_id=${selectedManufacturer.manufacturer_id}`;
    // if (val) {
    //   query = query + `&vehicle_type_id=${val}`;
    // }
    requestService.get(`vehicles`).then((response) => {
      // setVehicles(Object.values(groupedData));
      setVehicles(response.data);
    });
  };
  const getManufacturers = async () => {
    requestService.get("manufacturers").then((response) => {
      const groupedData = response.data.reduce((acc, item) => {
        const {
          manufacturer_id,
          manufacturer_name,
          country,
          established_year,
          vehicle_type_id,
          type_name,
        } = item;
        if (!acc[manufacturer_id]) {
          acc[manufacturer_id] = {
            manufacturer_id,
            manufacturer_name,
            country,
            established_year,
            vehicle_types: [],
          };
        }
        acc[manufacturer_id].vehicle_types.push({
          vehicle_type_id,
          type_name,
        });
        return acc;
      }, {});
      setData(Object.values(groupedData));
    });
  };
  useEffect(() => {
    // requestService.get("vehicle-types").then((response) => {
    //   setVehicleTypes(response);
    // });
    getManufacturers();
    getAllVehicles();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Vehicles{" "}
                </MDTypography>
              </MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={3} lg={3}>
                  Select manufacturer
                  <Select
                    name="manufacturer"
                    variant="outlined"
                    defaultValue={""}
                    onChange={(e) => setSelectedManufacturer(e.target.value)}
                  >
                    <MenuItem defaultValue={""}>Select</MenuItem>
                    {data &&
                      data?.map((item, index) => {
                        return (
                          <MenuItem value={item} key={`menu_${index}`}>
                            {item.manufacturer_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6} xl={3} lg={3}>
                  Select Vehicle Type
                  <Select
                    name="vehicletype"
                    variant="outlined"
                    onChange={(e) => getAllVehicles(e.target.value)}
                  >
                    <MenuItem defaultValue={""}>Select</MenuItem>
                    {selectedManufacturer &&
                      selectedManufacturer?.vehicle_types.map((item, index) => {
                        return (
                          <MenuItem value={item.vehicle_type_id} key={`menu_${index}`}>
                            {item.type_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6} xl={3} lg={3}></Grid>
              </Grid>
              <MDBox pt={3}></MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={model} onClose={() => setModel(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add Manufacturer</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ manufacturerName: "", manufacturerDetails: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.manufacturerName) {
                errors.manufacturerName = "Manufacturer Name is required";
              }
              if (!values.manufacturerDetails) {
                errors.manufacturerDetails = "Manufacturer Details are required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Handle form submission logic here
              requestService
                .post("manufacturers", {
                  name: values.manufacturerName,
                  country: values.manufacturerDetails,
                  established_year: values.established_year,
                  vehicle_types: selectedVehicleTypes,
                })
                .then((response) => {
                  alert("Manufacturer added successfully");
                  getManufacturers();
                  setSubmitting(false);
                  setModel(false); // Close the modal after submission
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <MDBox component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer Name</MDTypography>
                  <MDInput
                    type="text"
                    fullWidth
                    required
                    name="manufacturerName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.manufacturerName}
                    error={touched.manufacturerName && Boolean(errors.manufacturerName)}
                    helperText={touched.manufacturerName && errors.manufacturerName}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer Country</MDTypography>
                  <MDInput
                    name="manufacturerDetails"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.manufacturerDetails}
                    fullWidth
                  />
                  {touched.manufacturerDetails && errors.manufacturerDetails && (
                    <div style={{ color: "red" }}>{errors.manufacturerDetails}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2">Manufacturer established year</MDTypography>
                  <MDInput
                    name="established_year"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.established_year}
                    fullWidth
                  />
                  {touched.established_year && errors.established_year && (
                    <div style={{ color: "red" }}>{errors.established_year}</div>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDTypography variant="body2">Select Vehicle Types</MDTypography>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {vehicleTypes.map((vehicleType) => (
                          <FormControlLabel
                            key={vehicleType.vehicle_type_id}
                            control={
                              <Checkbox
                                checked={
                                  Array.isArray(selectedVehicleTypes) &&
                                  selectedVehicleTypes.includes(vehicleType.vehicle_type_id)
                                }
                                onChange={(event) => {
                                  const { checked } = event.target;
                                  const currentSelectedVehicleTypes = Array.isArray(
                                    selectedVehicleTypes
                                  )
                                    ? selectedVehicleTypes
                                    : [];
                                  if (checked) {
                                    setSelectedVehicleTypes([
                                      ...selectedVehicleTypes,
                                      vehicleType.vehicle_type_id,
                                    ]);
                                  } else {
                                    setSelectedVehicleTypes(
                                      selectedVehicleTypes.filter(
                                        (id) => id !== vehicleType.vehicle_type_id
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            label={vehicleType.type_name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>
                <MDButton type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Submit
                </MDButton>
              </MDBox>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Vehicles;
